import React, {useContext, useRef, useEffect, useState} from  'react';
import {Link, useNavigate, useParams,useLocation} from 'react-router-dom'
import Nav from "./Nav"
import Footer from "./Footer"
import SubjectsCards from './SubjectsCards'
// import SearchIcon from '@material-ui/icons/Search';
import {UserContex} from '../App'
import {socket} from "../App"
// import Button from "@material-ui/core/Button"
// import IconButton from "@material-ui/core/IconButton"
// import TextField from "@material-ui/core/TextField"
// import AssignmentIcon from "@material-ui/icons/Assignment"
// import PhoneIcon from "@material-ui/icons/Phone"
import VideocamIcon from '@mui/icons-material/Videocam';
// import { CopyToClipboard } from "react-copy-to-clipboard"
import Peer from "simple-peer"

function Chat() {
  const {subjectid} = useParams()
  const searchModal = useRef(null)
  const [search,setSearch] = useState('')
  const [title,setTitle] = useState('')
  const [body,setbody] = useState('')
  const [url,setUrl] = useState('')
  const [userDetails, setUserDetails] = useState([])
  const [data, setData] = useState([])
  const [message, setMessage] = useState([])
  const history = useNavigate()
  // const {state, dispatch}= useContext(UserContex)
  const [postdoubts,setPostDoubts] = useState(false)
  const [progress,setProgress] = useState(false)
  const [showChat,setShowChat] = useState(false)
  const [chatId,setChatID] = useState("")
  const [teachersName,setTeachersName] = useState("")
  const [smTeacherList,setSmTeacherList] = useState(true)
  const [info,setInfo] = useState({})
  // console.log("SRATE",query)
  const [ call, setCall] = useState(false)
//   const [ caller, setCaller] = useState(false)

  // const { subject, grade } = props.location.state;

  const [ me, setMe ] = useState("")
  const [ stream, setStream ] = useState()
  const [ receivingCall, setReceivingCall ] = useState(false)
  const [ caller, setCaller ] = useState("")
  const [ callerSignal, setCallerSignal ] = useState()
  const [ callAccepted, setCallAccepted ] = useState(false)
  const [ idToCall, setIdToCall ] = useState("")
  const [ id, setId ] = useState("")
  const [ callSocket, setCallsocket ] = useState("")
  const [ callEnded, setCallEnded] = useState(false)
  const [ name, setName ] = useState("")
  const myVideo = useRef()
  const userVideo = useRef()
  const connectionRef= useRef()

  const user = JSON.parse( localStorage.getItem("user"))

  var sum =0

console.log("Chat ID",chatId)

//   useEffect(() => {
//     // Register the event listener when the component mounts
   
//     // Clean up the event listener when the component unmounts
//     return () => {
//         socket.off('message', "handleNewMessage");
//     };
// }, []);




  useEffect(()=>{

    // getMyChatsStudent
    fetch(`/getMyChatsStudent`,{
        headers:{
            Authorization: "Bearer " +localStorage.getItem("jwt")
            
        }
    }).then(res=>res.json())
    .then(result=>{
        setData(result.chat)
    })

    fetch(`/addMySocketStudent`,{
        method:"put",
        headers:{
            "Content-Type":"application/json",
            Authorization: "Bearer " +localStorage.getItem("jwt")
            
        },
        body: JSON.stringify({
            socketid:socket.id
            
            // category
          })

    }).then(res=>res.json())
    .then(result=>{
        setData(result.chat)
    })
      
    // socket.on("me", (id) => {
        

    //     fetch(`/addMySocketStudent`,{
    //         method:"put",
    //         headers:{
    //             "Content-Type":"application/json",
    //             Authorization: "Bearer " +localStorage.getItem("jwt")
                
    //         },
    //         body: JSON.stringify({
    //             socketid:id
                
    //             // category
    //           })

    //     }).then(res=>res.json())
    //     .then(result=>{
    //         setData(result.chat)
    //     })
      
    // })
  

  },[])

  

 
    



//   const endCall = ()=>{
//     navigator.mediaDevices.getUserMedia({ video: false, audio: false }).then((stream) => {
//         setStream(stream)
//             myVideo.current.srcObject = stream
//     })
//   }



  // socket.on(`chat-${chatId}`, (data) => {
  //   console.log("CHAT SOCKET", sum+=1)
  //   setMessage(item => [...item, data]);
  // });

   

  socket.off(`chat-${chatId}`).on(`chat-${chatId}`, (message) => {

    // console.log("CHAT SOCKET",chatId)
  
    setMessage(item=>[...item, message])

    if (Array.isArray(data)) {

        // Find the index of the object with the matching _id
        const index = data.findIndex(item => item._id == chatId);
  
        // If the object is found and not already at the top
        if (index > -1) {
          // Create a new array to avoid mutating state directly
          const newData = [...data];
          const [foundObject] = newData.splice(index, 1); // Remove the object
          newData.unshift(foundObject); // Add it to the top
          setData(newData); // Update state with the new array
        }
      }

     
    });

  socket.off(`callrequest-${user._id}`).on(`callrequest-${user._id}`, (data) => {

    console.log("Call SOCKET",data)
  
            setCall(true)
            setCallsocket(data.socket)
            localStorage.setItem("socketid", data.socket)
            setCaller(data.name)
     
    });

    socket.on('receive_message', (chatId) => {
        if (Array.isArray(data)) {

            // Find the index of the object with the matching _id
            const index = data.findIndex(item => item._id == chatId);
            // If the object is found and not already at the top
            if (index > -1) {
              // Create a new array to avoid mutating state directly
              const newData = [...data];
              const [foundObject] = newData.splice(index, 1); // Remove the object
              newData.unshift(foundObject); // Add it to the top
              setData(newData); // Update state with the new array
            }
          }      });

  const PostData=()=>{

    socket.emit(`message`, {text:title,pic:url,chatId:chatId,askedBy:"Student"})

    fetch("/studentsmessage",{
      method:"put",
      headers:{
          "Content-Type":"application/json",
          Authorization: "Bearer " +localStorage.getItem("jwt")

      },
      body: JSON.stringify({

        text:title,
        pic:url,
        chatId
        // category
      })
      
  }).then(res=>res.json())
  .then(result=> {
     
      if(result.error){
          console.log("error")
      }
      else{
          // history.push('/')
          setTitle("")
          if (Array.isArray(data)) {
            console.log(result.chat._id)

            // Find the index of the object with the matching _id
            const index = data.findIndex(item => item._id == result.chat._id);
      
            console.log("IND",index)
            // If the object is found and not already at the top
            if (index > -1) {
              // Create a new array to avoid mutating state directly
              const newData = [...data];
              const [foundObject] = newData.splice(index, 1); // Remove the object
              newData.unshift(foundObject); // Add it to the top
              setData(newData); // Update state with the new array
            }
          }
          // setMessage( data.chat)
      }
  })
  .catch(err=>{
      console.log(err)
  })

   }

  const fetchPost=(query)=>{


    setSearch(query)

    fetch('/search-post',{

      method:"post",
      headers:{

        "Content-Type":"application/json"

      },
      body:JSON.stringify({
        query
      })

    }).then(res=> res.json())
      .then(results=>{
        
        setUserDetails(results.user)
      })


  }
  const singleChat=(chatId)=>{



    fetch(`/getsigleChat/${chatId}`,{

      method:"get",
      headers:{

        "Content-Type":"application/json",
        Authorization: "Bearer " +localStorage.getItem("jwt")


      }
    //   body:JSON.stringify({
    //     chatId
    //   })

    }).then(res=> res.json())
      .then(results=>{
        setIdToCall(results.chat.teacher.socketId)
        setId(results.chat.teacher._id)
        setChatID(results.chat._id)
        setMessage(results.chat.message)
      })


  }
    
  const uploadvid=({target:{files}})=>{

   
    let data = new FormData()

    for(const file of files){

   data.append('video', file)
   data.append('name',file.name)
}
       fetch("/video", {

         method:"post",
         body: data
     })
     .then(res=>res.json())
     .then(data=>{

         for(const path of data.message){
          setUrl(path.path)
          console.log(path.path)
         }

       
     })
     .catch(err=>{
         console.log(err)
     })

}



//   const uploadppt=({target:{files}})=>{

//    setProgress(true)
//     let data = new FormData()

//     for(const file of files){

//    data.append('ppt', file)
//    data.append('name',file.name)
// }
//        fetch("/ppt", {

//          method:"post",
//          body: data
//      })
//      .then(res=>res.json())
//      .then(data=>{

//          for(const path of data.message){
//           setUrl(path.path)
//           console.log(path.path)
//          }

       
//      })
//      .catch(err=>{
//          console.log(err)
//      })

// }


// useEffect(() => {
  
// }, [])

const VideoCall = ()=>{
    navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then((stream) => {
        setStream(stream)
            myVideo.current.srcObject = stream
    })

socket.on("me", (id) => {
        setMe(id)
    })

    socket.on("callUser", (data) => {
        setReceivingCall(true)
        setCaller(data.from)
        setName(data.name)
        setCallerSignal(data.signal)
    })
}


socket.on("callUser", (data) => {
    setReceivingCall(true)
    setCaller(data.from)
    setName(data.name)
    setCallerSignal(data.signal)
})

const callUser = (id) => {
    const peer = new Peer({
        initiator: true,
        trickle: false,
        stream: stream
    })
    peer.on("signal", (data) => {
        socket.emit("callUser", {
            userToCall: id,
            signalData: data,
            from: me,
            name: name
        })
    })
    peer.on("stream", (stream) => {
        
            userVideo.current.srcObject = stream
        
    })
    socket.on("callAccepted", (signal) => {
        setCallAccepted(true)
        peer.signal(signal)
    })

    connectionRef.current = peer
}

const answerCall =() =>  {
    setCallAccepted(true)
    const peer = new Peer({
        initiator: false,
        trickle: false,
        stream: stream
    })
    peer.on("signal", (data) => {
        socket.emit("answerCall", { signal: data, to: caller })
    })
    peer.on("stream", (stream) => {
        userVideo.current.srcObject = stream
    })

    peer.signal(callerSignal)
    connectionRef.current = peer
}

const leaveCall = () => {
    setCallEnded(true)
    connectionRef.current.destroy()
}

    return (
        <div>
             {/* Nav */}
            {/* <Nav/> */}
            {/* end Nav */}
            <header className="bg-white shadow">
   
        
        
      </header>
      <main>
          




    <div className="">
            <div className="flex bg-white ">
              
                <div className={`md:w-80 w-full  h-screen  bg-gray-100 p-2  ${smTeacherList?"block":"hidden"} md:block `}>
                    <div className="h-full overflow-y-auto">
                        <div className="text-xl font-extrabold text-gray-600  p-3"
                        
                        onClick={()=>{
                            history('/StudentDashboard')
                        }}
                        >

                        <svg class="h-8 w-8 text-purple-500"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <circle cx="12" cy="12" r="10" />  <polyline points="12 8 8 12 12 16" />  <line x1="16" y1="12" x2="8" y2="12" /></svg>
                        </div>
                        <div className="search-chat flex p-3">
                            <input className="input text-gray-700  text-sm p-3 focus:outline-none bg-gray-200  w-full rounded-l-md" type="text" placeholder="Search Messages"/>
                            <div className="bg-gray-200 flex justify-center items-center pr-3 text-gray-400 rounded-r-md">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                </svg>
                            </div>
                        </div>
                        <div className="text-lg font-semibol text-gray-600  p-1">Recent</div>

                        <div className="p-1">
                        <div class="container  w-full mx-auto">
    <ul class="flex flex-col">

        {data?data.map(item=>{


            return(
                <li class="flex flex-row mb-1 border-gray-400"
                onClick={()=>{setShowChat(true);singleChat(item._id);setTeachersName(item.teacher.name);setSmTeacherList(false)}}
                >
                <div class="shadow border select-none cursor-pointer bg-white  rounded-md flex flex-1 items-center p-4">
                    <div class="flex flex-col items-center justify-center w-10 h-10 mr-1">
                        <a href="#" class="relative block">
                            <img alt="profil" src="/images/person/6.jpg" class="mx-auto object-cover rounded-full h-10 w-10 "/>
                        </a>
                    </div>
                    <div class="flex-1 pl-1 md:mr-16">
                        <div class="font-medium ">
                            {item.teacher.name}
                        </div>
                        <div class="text-sm text-gray-600 ">
                            {item.teacher.subject}
                        </div>
                    </div>
                </div>
            </li>
            )
        }):<></>}
      
      
    </ul>
</div>

  
        </div>
                        {/* <Conversation/> */}
                    </div>



                </div>   
                        {showChat?
                          <div className="flex-grow  h-screen md:p-1 rounded-md">
                          <div className="flex-grow h-full flex flex-col">
                      <div className="w-full h-15 p-1 bg-gray-800  shadow-md rounded-xl rounded-bl-none rounded-br-none">
                          <div className="flex p-2 align-middle items-center">
                              <div className="p-2 md:hidden rounded-full mr-1 hover:bg-purple-500 text-white"
                              
                              onClick={()=>{

                                setShowChat(false); setSmTeacherList(true)
 
                              }}
                              
                              
                              >
                                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                                  </svg>
                              </div>
                              <div className="border rounded-full border-white p-1/2">
                                  <img className="w-14 h-14 rounded-full" src="https://cdn.pixabay.com/photo/2017/01/31/21/23/avatar-2027366_960_720.png" alt="avatar"/>
                              </div>
                              <div className="flex-grow p-2">
                                  <div className="text-md text-gray-50 font-semibold">{teachersName}</div>
                                  <div className="flex items-center">
                                      <div className="w-2 h-2 bg-green-300 rounded-full"></div>
                                      <div className="text-xs text-gray-50 ml-1">
                                      Online
                                      </div>
                                  </div>
                              </div>
                              {/* <Link to="/videocall"> */}
                              {/* <div className="p-2 text-white cursor-pointer hover:bg-purple-500 rounded-full"
                              
                              onClick={()=>{setCall(true);VideoCall()}}
                              >
                                 
                                  <VideocamIcon/>
                              </div> */}
                              {/* </Link> */}
                              
                         
                          </div>
                      </div>
                      <div className="w-full flex-grow bg-gray-100  my-2 p-2 overflow-y-auto">
                         
                         {message?
                         
                         message.map(item=>{

                          return(
                            <>
                             {  item.answeredBy?   <div className="flex items-end w-3/4" >
                          <img className="hidden w-8 h-8 m-3 rounded-full" src="https://cdn.pixabay.com/photo/2017/01/31/21/23/avatar-2027366_960_720.png" alt="avatar"/>  
                          <div className="w-8 m-3 rounded-full"/>
                          <div className="p-3 bg-purple-300  mx-3 my-1 rounded-2xl rounded-bl-none sm:w-3/4 md:w-3/6">
                             
                              <div className="text-gray-700 ">
                                  {item.text}
                              </div>
                            
                          </div>
                      </div>
                     :<></>
                     
                     }


                     {item.askedBy?
                     
                     <div className="flex justify-end">
                     <div className="flex items-end w-auto bg-purple-500  m-1 rounded-xl rounded-br-none sm:w-3/4 md:w-auto">
                         <div className="p-2">
                             <div className="text-gray-200">
                                 {item.text}
                             </div>
                         </div>
                     </div>
                 </div>:<></>}
                            </>



                          )

                       

                         })
                         
                         :<></>}
                         
                         
                       
                         
                         
                        
                        
                        
                         
                      </div>
                      <div className="h-15  p-3 rounded-xl rounded-tr-none rounded-tl-none bg-gray-100 ">
                          <div className="flex items-center">
                              <div className="p-2 text-gray-600  ">
                                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                  </svg>
                              </div>
                              <div className="search-chat flex flex-grow p-2">
                                  <input className="input text-gray-700  text-sm p-5 focus:outline-none bg-gray-100   flex-grow rounded-l-md" type="text" placeholder="Type your message ..."
                                  
                                  value={title}
                                  onChange={(e) => setTitle(e.target.value)}
                                  onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                      setPostDoubts(false);
                                      PostData();
                                    }
                                  }}
                                  />
                                  <div className="bg-gray-100    flex justify-center items-center pr-3 text-gray-400 rounded-r-md"
                                 onClick={() => { setPostDoubts(false); PostData() }}
    >
                                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
                                      </svg>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
          
                          </div>
                      :<></>}  
              
            </div>
        </div>

        

      </main>



      {
call? 
(
  <>
    <div
      className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
    >
      <div className="relative w-auto   relative w-auto  mx-auto ">
        {/*content*/}
        <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          {/*header*/}
          <div className="flex rounded-full items-start  p-2 ">
            
            <button
              className="p-1 ml-auto rounded-full bg-red-500 text-white text-3 "
              onClick={() => setCall(false)}
            >
<svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>            </button>
          </div>
          {/*body*/}
           



<div class="flex w-full max-w-sm content-center items-center ">
    <div class="w-full max-w-2xl px-5 py-6 m-auto  bg-white rounded-lg shadow ">
       
  
<div class="w-64 p-4 m-auto bg-white shadow-lg rounded-2xl ">
    <div class="w-full h-full text-center">
        <div class="flex flex-col justify-between h-full">
            <svg class="w-12 h-12 m-auto mt-4 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7">
                </path>
            </svg>
            <p class="px-6 py-2 text-gray-600  text-md">
                {caller+" "}
               
                wants you to join a call.
            </p>
            <div class="flex items-center justify-between w-full gap-4 mt-8">
                <a href='/videocall' >

                <button type="button" class="py-2 px-4  bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                    Join
                </button>
                </a>


            </div>
        </div>
    </div>
</div>


                </div>
        </div> 
         
 




         
        </div>
      </div>
    </div>
    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
  </>
)


:null}


      {/* <>
			<h1 style={{ textAlign: "center", color: '#fff' }}>Zoomish</h1>
		<div className="container">
			<div className="video-container">
				<div className="video">
					{stream &&  <video playsInline muted ref={myVideo} autoPlay style={{ width: "300px" }} />}
				</div>
				<div className="video">
					{callAccepted && !callEnded ?
					<video playsInline ref={userVideo} autoPlay style={{ width: "300px"}} />:
					null}
				</div>
			</div>
			<div className="myId">
				<TextField
					id="filled-basic"
					label="Name"
					variant="filled"
					value={name}
					onChange={(e) => setName(e.target.value)}
					style={{ marginBottom: "20px" }}
				/>
				<CopyToClipboard text={me} style={{ marginBottom: "2rem" }}>
					<Button variant="contained" color="primary" startIcon={<AssignmentIcon fontSize="large" />}>
						Copy ID
					</Button>
				</CopyToClipboard>

				<TextField
					id="filled-basic"
					label="ID to call"
					variant="filled"
					value={idToCall}
					onChange={(e) => setIdToCall(e.target.value)}
				/>
				<div className="call-button">
					{callAccepted && !callEnded ? (
						<Button variant="contained" color="secondary" onClick={leaveCall}>
							End Call
						</Button>
					) : (
						<IconButton color="primary" aria-label="call" onClick={() => callUser(idToCall)}>
							<PhoneIcon fontSize="large" />
						</IconButton>
					)}
					{idToCall}
				</div>
			</div>
			<div>
				{receivingCall && !callAccepted ? (
						<div className="caller">
						<h1 >{name} is calling...</h1>
						<Button variant="contained" color="primary" onClick={answerCall}>
							Answer
						</Button>
					</div>
				) : null}
			</div>
		</div>
		</> */}
            {/* Footer */}
            {/* <Footer/> */}
            {/* end Footer */}
        </div>
    )
}

export default Chat
