
import React,{useContext, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'


import {UserContex} from '../App'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export const Login_men = () => {

    
    const {state, dispatch }= useContext(UserContex)
    const history =useNavigate();
    const [email,setEmail] =  useState("");
    const [password,setPassword] =  useState("");
    const [show, setShow] = useState(false)


    const PostData =()=>{

   
        fetch("/signinTeacher",{
          method:"post",
          headers:{
              "Content-Type":"application/json"
          },
          body: JSON.stringify({
            
              password,
              email:email
          })
          
      }).then(res=>res.json())
      .then(data=> {
         
          if(data.error){
            toast.error(data.error)
            // console.log(data.error)
          }
          else{
              localStorage.setItem("jwt", data.token)
              localStorage.setItem("user", JSON.stringify( data.user))
              dispatch({type:"USER", payload:data.user})
              toast.success("Login Successful")

              history('/MentorDashboard')
          }
      })
      .catch(err=>{
          console.log(err)
      })
    
           }
    return (
        <div class="flex flex-wrap w-full">
    <div class="flex flex-col w-full md:w-1/2">
        <div class="flex justify-center pt-12 md:justify-start md:pl-12 md:-mb-24">
        <a href="/" class="p-2 text-4xl font-bold text-Our_purple_dark ">

        <svg class="h-12 w-12 text-indigo-400"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z"/>
</svg>
</a>
   <a href="#" class="p-4 text-4xl font-bold text-Our_purple_dark ">
                RIG Jr For Mentors
            </a>
        </div>
        <div class="flex flex-col justify-center px-8 pt-8 my-auto md:justify-start md:pt-0 md:px-24 lg:px-32">
            <p class="text-3xl text-center">
                Welcome To RIG Jr
            </p>
            <ToastContainer/>

                <div class="flex flex-col pt-4">
                    <div class="flex relative ">
                        <span class="rounded-l-md inline-flex  items-center px-3 border-t bg-white border-l border-b  border-gray-300 text-gray-500 shadow-sm text-sm">
                            <svg width="15" height="15" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1792 710v794q0 66-47 113t-113 47h-1472q-66 0-113-47t-47-113v-794q44 49 101 87 362 246 497 345 57 42 92.5 65.5t94.5 48 110 24.5h2q51 0 110-24.5t94.5-48 92.5-65.5q170-123 498-345 57-39 100-87zm0-294q0 79-49 151t-122 123q-376 261-468 325-10 7-42.5 30.5t-54 38-52 32.5-57.5 27-50 9h-2q-23 0-50-9t-57.5-27-52-32.5-54-38-42.5-30.5q-91-64-262-182.5t-205-142.5q-62-42-117-115.5t-55-136.5q0-78 41.5-130t118.5-52h1472q65 0 112.5 47t47.5 113z">
                                </path>
                            </svg>
                        </span>
                        <input type="text" id="design-login-email" class=" flex-1  appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" placeholder="Email"
                        
                        
                        value={email}
                        onChange={(e)=>setEmail(e.target.value)}
                        />
                        </div>
                    </div>
                    <div class="flex flex-col pt-4 mb-12">
                        <div class="flex relative ">
                            <span class="rounded-l-md inline-flex  items-center px-3 border-t bg-white border-l border-b  border-gray-300 text-gray-500 shadow-sm text-sm">
                                <svg width="15" height="15" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1376 768q40 0 68 28t28 68v576q0 40-28 68t-68 28h-960q-40 0-68-28t-28-68v-576q0-40 28-68t68-28h32v-320q0-185 131.5-316.5t316.5-131.5 316.5 131.5 131.5 316.5q0 26-19 45t-45 19h-64q-26 0-45-19t-19-45q0-106-75-181t-181-75-181 75-75 181v320h736z">
                                    </path>
                                </svg>
                            </span>
                            <input type={show?"text":"password"} id="design-login-password" class=" flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" placeholder="Password"
                            
                            value = {password}
                            onChange={(e)=>setPassword(e.target.value)}
                            />
                            {
                                show?<svg class="h-8 w-8 text-gray-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor"
                                onClick={()=>{setShow(false)}}
                                >
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"/>
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"/>
                              </svg>
                              :<svg class="h-8 w-8 text-gray-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor"
                              onClick={()=>{setShow(true)}}

                              >
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"/>
                              </svg>
                              
                            }
                            </div>
                        </div>

                        <button type="submit"  class="py-2 px-4  bg-Our_purple_dark hover:bg-purple-700 focus:ring-purple-500 focus:ring-offset-purple-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                             onClick={()=>PostData()}
                        >
                            <span class="w-full">
                                Login In
                            </span>
                        </button>
                    
                    <div class="flex items-center justify-center mt-6">
                <a href="/registertutor"  class="inline-flex items-center text-xs font-thin text-center text-gray-600 hover:text-gray-700 ">
                    <span class="ml-2">
                        You don&#x27;t have an account?
                    </span>
                </a>
            </div>
                    <div class="flex items-center justify-center mt-6">
                <a href="/reset"  class="inline-flex items-center text-xs font-thin text-center text-gray-600 hover:text-gray-700 ">
                    <span class="ml-2">
                        Forgot Password?
                    </span>
                </a>
            </div>
                </div>
            </div>
            <div class="w-1/2 shadow-2xl bg-Our_purple_dark">
            <img class="hidden object-cover w-full h-screen md:block" src={require("../assets/img/Men_Login.png")}/>
            </div>
        </div>
    )
}
